<template>
  <div>
    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <response-form-card
        v-if="selectedResponse !== null"
        :reloadParent="load"
        :response="selectedResponse"
        :closeSidebar="closeSidebar"
      />
    </b-sidebar>
    <b-overlay
      :show="showOverlay"
      opacity="0.17"
      blur="1rem"
      rounded="md"
      variant="secondary"
    >
      <div class="w-100 card" v-if="currentQuestionnaire !== null">
        <div
          class="card-body d-flex flex-row justify-content-center align-items-center"
        >
          <div
            class="d-flex flex-column align-items-start justify-content-start"
            style="width: 60%"
          >
            <h2 class="text-primary">{{ currentQuestionnaire.title }}</h2>
            <p class="text-muted">{{ currentQuestionnaire.description }}</p>
          </div>
          <div
            class="d-flex flex-row align-items-center justify-content-around"
            style="width: 39%"
          >
            <div
              class="d-flex flex-column align-items-center justify-content-around"
            >
              <h4 class="font-weight-bolder">Gap</h4>
              <donut-bar
                :percentage="Number(currentQuestionnaire.gap.toFixed(2))"
                :displayValue="currentQuestionnaire.gap + '%'"
                size="sm"
                :uniqueId="currentQuestionnaire._id + 1"
              />
            </div>

            <div
              class="d-flex flex-column align-items-center justify-content-around"
            >
              <h4 class="font-weight-bolder">Progress</h4>
              <donut-bar
                :percentage="Number(currentQuestionnaire.completion.toFixed(2))"
                :displayValue="currentQuestionnaire.completion + '%'"
                size="sm"
                :uniqueId="currentQuestionnaire._id + 2"
                :colorsReversed="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div
          class="card-header d-flex flex-column align-items-start justify-content-start"
        >
          <h2 class="text-turncate mb-50">Questions & Responses</h2>
          <p
            v-if="isUserVendor == true"
            class="mb-0text-muted font-weight-bold"
          >
            Click on any question to start the assessment.
          </p>
          <p v-else class="mb-0text-muted font-weight-bold">
            Click on any question to view the assessment.
          </p>
        </div>
        <div class="card-body">
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">Question</th>
                <th role="columnheader" scope="col">Description</th>
                <th role="columnheader" scope="col" class="text-center">
                  Response
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr
                v-for="question in responses"
                :key="question._id"
                role="row"
                class="cursor-pointer"
                @click="handleQuestionClick(question)"
              >
                <td role="cell">
                  <div class="d-flex justify-content-start">
                    <p
                      class="text-left text-primary text-truncate font-weight-bold mb-0"
                      v-b-tooltip.hover.top.v-secondary
                      :title="question.title"
                    >
                      {{ question.title }}
                    </p>
                  </div>
                </td>

                <td role="cell" style="padding-right: 0">
                  <div class="d-flex justify-content-start">
                    <p
                      class="text-left text-truncate font-italic font-weight-bold mb-0"
                      v-b-tooltip.hover.top.v-secondary
                      :title="question.description"
                    >
                      {{ question.description }}
                    </p>
                  </div>
                </td>

                <td role="cell">
                  <div class="d-flex justify-content-center">
                    <b-badge variant="primary">{{
                      getResponseValue(question.response)
                    }}</b-badge>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BButton,
  BListGroup,
  BListGroupItem,
  BSidebar,
  BCard,
  BBadge,
  BOverlay,
  BFormSelect,
  VBTooltip,
  BCol,
  BFormTextarea,
  BProgress,
  VBHover,
  BAvatar,
  BPagination,
} from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { VBToggle } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import ProgressBar from "../../components/ProgressBar.vue";
import DonutBar from "../../components/DonutBar.vue";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import ResponseFormCard from "./components/ResponseFormCard.vue";

export default {
  name: "QuestionnaireQuestionAssessment",
  components: {
    BForm,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    draggable,
    BSidebar,
    BCard,
    VBToggle,
    BBadge,
    BOverlay,
    BFormSelect,
    VueAutosuggest,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BPagination,
    BFormTextarea,
    BProgress,
    ProgressBar,
    DonutBar,
    VBHover,
    BAvatar,
    ResponseFormCard,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    "b-hover": VBHover,
  },
  data() {
    return {
      currentQuestionnaire: null,
      responses: null,
      showOverlay: false,
      selectedResponse: null,
      activeResponseId: null,
      currentResponseIndex: 0,
      isUserVendor: false,
      filters: {
        page: 1,
      },
      pagination: {
        perPage: 10,
        lastPage: 1,
      },
    };
  },

  mixins: [ResponseMixins, ThirdPartyRisksMixins],

  mounted() {
    this.load();
  },

  watch: {
    filters: {
      handler: function (newValue) {
        this.getQuestionnaireResponsesAndSetData(
          newValue,
          this.$route.params.id,
          this.$route.params.assessmentId,
          this.$route.params.questionnaireId
        );
      },
      deep: true,
    },
    currentResponseIndex(newValue) {
      const newSelectedResponse = this.responses[newValue];
      this.setSelectedResponseData(newSelectedResponse);
    },
  },

  methods: {
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }
      this.getQuestionnaireResponsesAndSetData(
        this.filters,
        this.$route.params.assessmentId,
        this.$route.params.questionnaireId
      );
      this.getQuestionnaireDetailsAndSetData(
        this.$route.params.id,
        this.$route.params.assessmentId,
        this.$route.params.questionnaireId
      );
    },

    handleQuestionClick(question) {
      this.setSelectedResponseData(question);
      this.openSidebar();
    },

    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Unknown";
        }
      }
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },

    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        default:
          return "info";
      }
    },
    getQuestionnaireDetailsAndSetData(vendorId, assessmentId, questionnaireId) {
      this.showOverlay = true;
      this.getQuestionnaireDetails(vendorId, assessmentId, questionnaireId)
        .then((res) => {
          this.currentQuestionnaire = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getQuestionnaireResponsesAndSetData(params, assessmentId, questionnaireId) {
      (this.showOverlay = true),
        this.getQuestionnaireResponses(params, assessmentId, questionnaireId)
          .then((res) => {
            console.log(res);
            this.responses = res.data.data.data;
            this.setSelectedResponseData(res.data.data.data[0]);
            this.pagination.lastPage = res.data.data.last_page;
            this.pagination.perPage = res.data.data.per_page;
            this.filters.page = res.data.data.current_page;
          })
          .catch((err) => {
            this.handleError(err);
            console.log(err);
          })
          .finally(() => {
            this.showOverlay = false;
          });
    },

    handleNextClick() {
      const currentIndex = this.responses.findIndex(
        (res) => res._id == this.activeResponseId
      );
      if (currentIndex < this.responses.length) {
        const nextResponse = this.responses[currentIndex + 1];
        this.setSelectedResponseData(nextResponse);
      }
    },
    handlePreviousClick() {
      const currentIndex = this.responses.findIndex(
        (res) => res._id == this.activeResponseId
      );
      if (currentIndex > 0) {
        const nextResponse = this.responses[currentIndex - 1];
        this.setSelectedResponseData(nextResponse);
      }
    },

    handleResponseClick(response) {
      this.setSelectedResponseData(response);
    },

    setSelectedResponseData(response) {
      this.selectedResponse = response;
      this.activeResponseId = response._id;
      this.currentResponseIndex = this.responses.findIndex(
        (res) => res._id == response._id
      );
    },

    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Not Applicable";
        }
      }
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="scss" scoped>
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.icon-container {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 3px;
}

.sidebar-left {
  width: 25%;

  .todo-sidebar {
    width: 100%;
  }
}

.todo-bottom-section {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.bottom-area {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 200px;
}

.list-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
  transition: all 0.4s ease-in-out;

  &:hover {
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    //   rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }

  .header-section {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
